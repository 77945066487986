@charset "utf-8";
/* CSS Document */

/* Styles written by Jacob Bearce | jacob@weblinxinc.com | jacob@bearce.me */

/* imports */

@import "../vendor/_jquery.bjqs";
@import "../vendor/_normalize";
@import "../vendor/_recaptcha";
@import "../vendor/_ss-social-regular";

@import "_text";
@import "_header";
@import "_content";
@import "_footer";

/* general */

body.front-page {
	background: url("../media/body-front.jpg") center 50px no-repeat #293484;
}

@media screen and (max-width: 1280px) {
	body.front-page {
		background: url("../media/body.jpg") center top no-repeat #293484;
	}
}

body.internal-page {
	background: url("../media/body-internal.jpg") center top no-repeat #293484;
}

body.short,
body.tribe-is-responsive {
	background: url("../media/body-short.jpg") center top no-repeat #293484;
}

@media screen and (max-width: 1280px) {
	.page {
		padding-left: 28px;
		padding-right: 28px;
	}
}

@media screen and (max-width: 768px) {
	.page  {
		padding-left: 18px;
		padding-right: 18px;
	}
}

/* independent classes */

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.alignleft {
	float: left;
}

.alignright {
	float: right;
}

.desktop-hidden {
	display: none;
}

@media screen and (max-width: 640px) {
	.desktop-hidden {
		display: block;
	}

	.mobile-hidden {
		display: none;
	}
}

.show480 {
	display: none;
}

@media screen and (max-width: 480px) {
	.show480 {
		display: block;
	}

	.hide480 {
		display: none;
	}
}

@media screen and (max-width: 480px) {
	.fullmobile {
		display: block;
		float: none;
		height: auto;
		margin: 0 auto 11px;
		max-width: 100%;
		width: 100%;
	}
}

/* IE Snap Fix */

@-ms-viewport {
    width: device-width;
}
