@charset "utf-8";
/* CSS Document */

/* Styles written by Jacob Bearce | jacob@weblinxinc.com | jacob@bearce.me */

.page > header {
	background: #293484;
	border-bottom: 2px solid #C4CDE9;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
}

/*
@media screen and (max-width: 640px) {
	.page > header {
		left: auto;
		position: static;
		top: auto;
	}
}
*/

header:after {
	clear: both;
	content: "\0020";
	display: block;
	visibility: hidden;
	zoom: 1;
}

	/* CSS3 Responsive Navigation v2.0 built by Jacob Bearce | jacob@weblinxinc.com | jacob@bearce.me */

	.page > header nav {
        display: flex;
        flex-wrap: wrap;
		margin: 0 auto;
		position: relative;
		width: 1154px;
		z-index: 999;
	}

	@media screen and (max-width: 1280px) {
		.page > header nav {
             align-items: center;
             justify-content: space-around;
			max-width: 1174px;
			padding: 0 10px;
			width: auto;
		}
	}

	@media screen and (max-width: 1024px) {
		.page > header nav {
            flex-flow: column;
		}
	}

	@media screen and (max-width: 768px) {
		.page > header nav {
			background: none;
             display: block;
			float: none;
			padding: 0;
		}
	}

		.page > header nav > a {
			display: block;
			float: left;
			padding: 5px 4px;
			width: 230px;
		}

        @media screen and (max-width: 1088px) {
            .page > header nav > a {
                margin-top: 18px;
            }
        }

		@media screen and (max-width: 1280px) {
			.page > header nav > a {
				width: 213px;
			}
		}

		@media screen and (max-width: 960px) {
			.page > header nav > a {
				width: 183px;
			}
		}

		@media screen and (max-width: 768px) {
			.page > header nav > a {
				float: left;
				padding: 5px 30px;
				width: 223px;
                margin-top: 2px;
			}
		}

			.page > header nav > a img {
				display: block;
				max-width: 100%;
			}

        .page > header nav .navigation-wrapper {
            align-items: center;
            display: flex;
            flex-wrap: nowrap;
        }

        @media screen and (max-width: 768px) {
            .page > header nav .navigation-wrapper {
                display: block;
            }
        }

        .page > header nav .navigation-wrapper .menu-main-navigation-container {
            flex: 1 0 auto;
        }

            .page > header nav label, .page > header nav input[type=checkbox] {
			display: none;
		}

		.page > header nav > .navigation-wrapper div > label {
			content: "\0020";
			display: none;
			float: right;
			height: 44px;
			margin: 0;
			padding: 0;
			position: relative;
			width: 78px;
		}

		@media screen and (max-width: 768px) {
			.page > header nav > .navigation-wrapper div > label {
				display: block;
                top: 16px;
			}
		}

		.page > header nav > .navigation-wrapper div > label:after,
		.page > header nav > .navigation-wrapper div > label:before,
		.page > header nav > .navigation-wrapper div > label em {
			border-top: 4px solid #FFFFFF;
			content: "\0020";
			display: block;
			height: 0;
			left: 30px;
			overflow: hidden;
			position: absolute;
			right: 30px;
			top: 50%;
		}

			.page > header nav > .navigation-wrapper div > label:after {
				margin-top: 3px;
			}

			.page > header nav > .navigation-wrapper div > label:before {
				margin-top: -9px;
			}

			.page > header nav > .navigation-wrapper div > label em {
				height: 0;
				margin-top: -3px;
			}

		.page > header nav ul {
			color: #FFF;
			float: right;
			font-family: "Montserrat", sans-serif;
			font-size: 14px;
			font-weight: bold;
			line-height: normal;
			list-style: none;
			margin: 0 auto;
			padding: 0;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			width: auto;
			z-index: 999;
		}

		@media screen and (max-width: 960px) and (min-width: 769px) {
			.page > header nav ul {
				font-size: 13px;
			}
		}

		@media screen and (max-width: 853px) and (min-width: 769px) {
			.page > header nav ul {
				font-size: 11px;
			}
		}


		@media screen and (max-width: 768px) {
			.page > header nav ul {
				clear: both;
				float: none;
				height: auto;
				max-height: 0;
				overflow: hidden;
				right: auto;
				width: 100%;
				visibility: hidden;
				/* css3 */
				-webkit-transition: max-height 0.5s, visibility 0s 0.5s !important;
				-moz-transition: max-height 0.5s, visibility 0s 0.5s !important;
				-ms-transition: max-height 0.5s, visibility 0s 0.5s !important;
				transition: max-height 0.5s, visibility 0s 0.5s !important;
			}

			.page > header nav input[type=checkbox]:checked + ul {
				max-height: 2000px;
				visibility: visible;
				/* css3 */
				-webkit-transition: max-height 1s, visibility 0s 0s !important;
				-moz-transition: max-height 1s, visibility 0s 0s !important;
				-ms-transition: max-height 1s, visibility 0s 0s !important;
				transition: max-height 1s, visibility 0s 0s !important;
			}
		}

			.page > header nav ul:after {
				clear: both;
				content: "\0020";
				display: block;
				visibility: hidden;
				zoom: 1;
			}

			.page > header nav ul li {
				background-image: none;
				float: left;
				position: relative;
			}

			@media screen and (max-width: 768px) {
				.page > header nav ul li {
					border-bottom: 1px solid rgba(0,0,0,0.1);
					border-top: 1px solid rgba(255,255,255,0.1);
					float: none;
					left: auto;
				}
			}

			/*@media screen and (max-width: 1280px) and (min-width: 769px) {
				.page > header nav ul li:first-child {
					display: none;
				}
			}*/

			.page > header nav ul li:hover {
			}

			@media screen and (max-width: 768px) {
				.page > header nav ul li:hover {
					background: none;
				}
			}

				.page > header nav ul li:before {
					display: none;
				}

				.page > header nav ul li a {
					color: #FFF;
					display: block;
					overflow: hidden;
					padding: 25px 12px;
					position: relative;
				}

				@media screen and (max-width: 1024px) {
					.page > header nav ul li a {
						padding-left: 11px;
						padding-right: 11px;
					}
				}

				@media screen and (max-width: 960px) {
					.page > header nav ul li a {
						padding: 16px 8px;
					}
				}

				@media screen and (max-width: 768px) {
					.page > header nav ul li a {
						padding: 12px 40px !important;
					}
				}

				.menu > .menu-item > a:hover,
				.sub-menu > .menu-item > a:hover {
                      color: #C4CDE9;
				}

					.page > header > nav > ul > li > a:after {
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-top: 10px solid #C7A946;
						content: "\0020";
						height: 0;
						left: 50%;
						margin-left: -10px;
						position: absolute;
						top: -10px;
						width: 0;
						/* css3 */
						-webkit-transition: top 0.25s;
						-moz-transition: top 0.25s;
						-ms-transition: top 0.25s;
						transition: top 0.25s;
					}

					@media screen and (max-width: 853px) {
						.page > header > nav > ul > li > a:after {
							border-left-width: 8px;
							border-right-width: 8px;
							border-top-width: 8px;
							margin-left: -8px;
							top: -8px;
						}
					}

					@media screen and (max-width: 768px) {
						.page > header > nav > ul > li > a:after {
							display: none;
						}
					}

					.page > header > nav > ul > li:hover > a:after,
					.page > header > nav > ul > li > a:hover:after {
						top: 0;
					}

				.page > header nav ul li a[class^="ss-"] {
					font-size: 0px;
					padding: 0;
				}

					.page > header nav ul li a[class^="ss-"]:before {
						color: #FFFFFF;
						display: block;
						font-size: 23px;
						padding: 17px 5px 12px;
						transition: color 0.25s;
					}

					@media screen and (max-width: 960px) and (min-width: 769px) {
						.page > header nav ul li a[class^="ss-"]:before {
							font-size: 20px;
							padding-bottom: 8px;
							padding-top: 13px;
						}
					}

					@media screen and (max-width: 853px) and (min-width: 769px) {
						.page > header nav ul li a[class^="ss-"]:before {
							padding-bottom: 10px;
							font-size: 17px;
						}
					}

					@media screen and (max-width: 768px) {
						.page > header nav ul li a[class^="ss-"]:before {
							padding: 0;
						}
					}

					.page > header nav ul li a[class^="ss-"]:hover:before {
						color: #C7A946;
					}

				.page > header nav ul li label {
					display: none;
					height: 43px;
					position: absolute;
					right: 0;
					top: 0;
					width: 43px;
				}

				@media screen and (max-width: 768px) {
					.page > header nav ul li label {
						display: block;
					}
				}

					.page > header nav ul li label:after {
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-top: 10px solid #FFFFFF;
						content: "\0020";
						height: 0;
						left: 50%;
						margin-left: -10px;
						margin-top: -7px;
						position: absolute;
						top: 50%;
						width: 0;
					}

					.page > header nav ul li label em {
						left: -9999em;
						position: absolute
					}

				/* sub list */

				.page > header nav ul li ul {
					background: #293484;
					left: -999em;
					opacity: 0;
					position: absolute;
					text-align: left;
					top: 100%;
					width: 250px;
					/* css3 */
					-webkit-transition: opacity 0.25s 0s, left 0s 0.25s;
					-moz-transition: opacity 0.25s 0s, left 0s 0.25s;
					-ms-transition: opacity 0.25s 0s, left 0s 0.25s;
					transition: opacity 0.25s 0s, left 0s 0.25s;
				}

				@media screen and (max-width: 768px) {
					.page > header nav ul li ul {
						left: auto !important;
						max-height: 0;
						opacity: 1;
						overflow: hidden;
						position: relative;
						text-align: center;
						top: auto;
						visibility: hidden;
						width: 100%;
					}
				}

				.page > header nav ul li:hover > ul {
					/* css3 */
					-webkit-transition: opacity 0.25s 0s, left 0s 0s;
					-moz-transition: opacity 0.25s 0s, left 0s 0s;
					-ms-transition: opacity 0.25s 0s, left 0s 0s;
					transition: opacity 0.25s 0s, left 0s 0s;
				}

				.page > header nav ul li:hover > ul {
					left: -4px;
					opacity: 1;
				}

					.page > header nav ul li ul li {
						float: none;
						left: auto;
						width: 100%;
					}

					@media screen and (max-width: 768px) {
						.page > header nav ul li ul li:last-of-type {
							border-bottom: 0;
						}
					}

					.page > header nav ul li ul li:hover {
					}

						.page > header nav ul li ul li a {
							padding: 10px 20px;
						}

						.page > header nav > ul > li > ul > li > a:hover,
						.page > header nav > ul > li > ul > li:hover > a {
						}

							.page > header nav ul li ul li a:after {
								border-bottom: 10px solid transparent;
								border-left: 10px solid #C4CDE9;
								border-top: 10px solid transparent;
								content: "\0020";
								height: 0;
								left: -10px;
								margin-top: -10px;
								position: absolute;
								top: 50%;
								width: 0;
								/* css3 */
								-webkit-transition: left 0.25s;
								-moz-transition: left 0.25s;
								-ms-transition: left 0.25s;
								transition: left 0.25s;
							}

							@media screen and (max-width: 853px) {
								.page > header nav ul li ul li a:after {
									border-bottom-width: 8px;
									border-left-width: 8px;
									border-top-width: 8px;
									margin-top: -8px;
									left: -8px;
								}
							}

							@media screen and (max-width: 768px) {
								.page > header nav ul li ul li a:after {
									display: none;
								}
							}

							.page > header nav ul li ul li:hover > a:after,
							.page > header nav ul li ul li > a:hover:after {
								left: 0;
							}

						/* sub sub list */

						.page > header nav ul li ul li ul {
							background: url("../media/black80.png");
							background: rgba(0,0,0,0.6);
							top: 0;
							width: 250px;
						}

						@media screen and (max-width: 768px) {
							.page > header nav ul li ul li ul {
								width: 100%;
							}
						}

						.page > header nav ul li ul li:hover > ul {
							left: 100%;
						}

							.page > header nav ul li ul li ul li {
							}

							.page > header nav ul li ul li ul li:hover {
							}

								.page > header nav ul li ul li ul li a {
								}

								.page > header nav ul li ul li ul li a:hover {
								}

        @media screen and (max-width: 768px) {
            .page > header nav .social-wrapper {
                display: none;
            }

            .page > header nav.open-menu .social-wrapper {
                display: block;
            }
        }

            .page > header nav .social-wrapper .social {
                align-items: center;
                background: #FFFFFF;
                border-radius: 4px;
                display: flex;
                justify-content: space-around;
                padding: 0 5px;
            }

            @media screen and (max-width: 768px) {
                .page > header nav .social-wrapper .social {
                    margin: 15px auto;
                    width: 100px;
                }
            }

            .page > header nav .social-wrapper .social a {
                padding: 8px 9px 5px;
            }

            @media screen and (max-width: 1280px) {
                .page > header nav .social-wrapper .social a {
                    padding: 8px 9px 5px;
                }
            }

            @media screen and (max-width: 960px)  {
                .page > header nav .social-wrapper .social a {
                    padding: 5px 6px 2px;
                }
            }

                @media screen and (max-width: 960px) and (min-width: 769px) {
                    .page > header nav .social-wrapper .social img {
                        max-height: 20px;
                    }
                }
@media screen and (max-width: 600px) {
	div#wpadminbar {
		position: fixed;
	}
}

.page > header.is_user_logged_in {
	top: 32px;
}

@media screen and (max-width: 780px) {
	.page > header.is_user_logged_in {
		top: 42px;
	}
}
