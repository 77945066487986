/* Basic jQuery Slider essential styles */

.bjqs {
    border: 2px solid #C4CDE9;
	display: none;
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
}

	.bjqs figure {
		display: none;
		margin: 0;
		position: absolute;
	}

		.bjqs figure figcaption {
			bottom: 0;
			display: block;
			margin: 0;
			padding: 2%;
			position: absolute;
			width: 100%;
		}

ul.bjqs-controls {
	display: none;
	list-style: none;
	margin: 0;
	padding: 0;
	z-index: 9999;
}

	ul.bjqs-controls.v-centered li a {
		position: absolute;
	}

	ul.bjqs-controls.v-centered li:first-child a {
		right: 0;
	}

	ul.bjqs-controls.v-centered li:last-child a {
		left: 0;
	}

ol.bjqs-markers {
	display: none;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

ol.bjqs-markers.h-centered {
	text-align: center;
}

	ol.bjqs-markers li {
		display: inline;
	}

		ol.bjqs-markers li a {
			display: inline-block;
		}

/* custom styles */

ul.bjqs-controls.v-centered li a {
	background: #FFF;
	color: #000;
	display: block;
	padding: 10px;
	text-decoration: none;
}

ul.bjqs-controls.v-centered li a:hover {
	background: #000;
	color: #FFF;
}

ol.bjqs-markers li a {
	background: #000;
	color: #FFF;
	margin: 5px;
	padding: 5px 10px;
	text-decoration: none;
}

ol.bjqs-markers li.active-marker a,
ol.bjqs-markers li a:hover {
	background: #999;
}

.bjqs figure figcaption{
	background: #FFF;
}