@charset "utf-8";
/* CSS Document */

/* Styles written by Jacob Bearce | jacob@weblinxinc.com | jacob@bearce.me */

.footer {
    background: #293484;
	margin: 0 auto;
	padding: 0 12px 35px;
}

body.short .footer {
    margin-top: 50px;
}

body.tribe-is-responsive .footer {
    margin-top: 65px;
}

@media screen and (max-width: 1280px) {
	.footer {
		margin-top: 40px;
        padding: 0 40px 40px;
	}
}

@media screen and (max-width: 960px) {
	:not(body.short) .footer {
		margin-top: 0;
	}
}

footer {
	margin: 0 auto;
    padding-top: 35px;
	width: 1144px;
}

@media screen and (max-width: 1280px) {
	footer {
		max-width: 1144px;
		width: auto;
	}
}

	footer:after {
		clear: both;
		content: "\0020";
		display: block;
		visibility: hidden;
		zoom: 1;
	}

	footer a {
		color: #FFFFFF;
	}

	@media screen and (max-width: 640px) {
		footer a {
			color: #FFF;
		}
	}

	footer a:hover {
		color: #C4CDE9;
	}

	footer input,
	footer label,
	footer ol,
	footer p,
	footer .select,
	footer select,
	footer table,
	footer textarea,
	footer ul {
		color: #FFFFFF;
		font-size: 13px;
		margin: 0;
	}

	@media screen and (max-width: 640px) {
		footer input,
		footer label,
		footer ol,
		footer p,
		footer .select,
		footer select,
		footer table,
		footer textarea,
		footer ul {
			color: #FFF;
		}
	}

	footer > section:first-child {
		display: table;
		margin: 45px 0 33px;
		width: 100%;
	}

	@media screen and (max-width: 640px) {
		footer > section:first-child {
			display: none;
		}
	}

		footer > section:first-child > section {
			display: table-row;
		}

			footer > section:first-child > section > div {
				display: table-cell;
				padding: 0 10px;
			}

	footer nav {
		float: left;
		width: 50%;
	}

	@media screen and (max-width: 853px) {
		footer nav {
			float: none;
			margin-bottom: 15px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			width: auto;
		}
	}

	@media screen and (max-width: 640px) {
		footer nav {
			margin-top: 19px;
		}
	}

	@media screen and (max-width: 480px) {
		footer nav {
			display: none;
		}
	}

		footer nav ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

			footer nav ul li {
				display: inline;
			}

				footer nav ul li:not(:last-child):after {
					content: "\002D";
					padding: 0 0 0 4px;
				}

	footer > section:last-child {
		float: right;
		text-align: right;
		width: 50%;
	}

	@media screen and (max-width: 853px) {
		footer > section:last-child {
			float: none;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			width: auto;
		}
	}

	@media screen and (max-width: 480px) {
		footer > section:last-child {
			margin-top: 19px;
		}
	}