@charset "utf-8";
/* CSS Document */

/* Styles written by Jacob Bearce | jacob@weblinxinc.com | jacob@bearce.me */

.slideshowWrapper {
	margin: 185px auto 26px;
	padding: 0 12px;
	position: relative;
	width: 1168px;
	z-index: 800;
}

@media screen and (max-width: 1280px) {
	.slideshowWrapper {
        margin-top: 250px;
		max-width: 1144px;
		position: relative;
		width: auto;
	}
}

@media screen and (max-width: 960px) {
	.slideshowWrapper {
		margin-top: 145px;
		/* css3 */
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-flex-direction: column;
		-moz-box-orient: vertical;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@media screen and (max-width: 853px) {
	.slideshowWrapper {
		margin-top: 86px;
	}
}

@media screen and (max-width: 768px) {
	.slideshowWrapper {
		margin-top: 126px;
	}
}

@media screen and (max-width: 640px) {
	.slideshowWrapper {
		margin-top: 90px;
	}
}

.slideshowWrapper:after {
	clear: both;
	content: "\0020";
	display: block;
	visibility: hidden;
	zoom: 1;
}

.slideshowWrapper > section {
	float: left;
	width: 444px;
	width: 38.811188811188811188811188811189%;
}

@media screen and (max-width: 1280px) and (min-width: 961px) {
	.slideshowWrapper > section {
		height: 163px;
		position: absolute;
		top: 50%;
		width: 50%;
		/* css3 */
		-webkit-transform: translate(0%, -50%);
		-moz-transform: translate(0%, -50%);
		-ms-transform: translate(0%, -50%);
		transform: translate(0%, -50%);
	}
}

@media screen and (max-width: 960px) {
	.slideshowWrapper > section {
		float: none;
		width: auto;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 2;
		-moz-box-ordinal-group: 2;
		-ms-flex-order: 2;
		order: 2;
	}
}

.slideshowWrapper > section > img {
	display: block;
	margin: -15px auto 35px;
}

@media screen and (max-width: 1280px) {
	.slideshowWrapper > section > img {
		margin-bottom: 10px;
		margin-top: -65px;
		width: 87px;
	}
}

@media screen and (max-width: 960px) {
	.slideshowWrapper > section > img {
		display: none;
	}
}

.slideshowWrapper > section address {
	background: rgba(41, 52, 132, 0.8);
	bottom: 10px;
	display: block;
	padding: 20px 25px;
	position: absolute;
	width: 38%;
	/* css3 */
	-webkit-border-radius: 10px 0 0 10px;
	-moz-border-radius: 10px 0 0 10px;
	-ms-border-radius: 10px 0 0 10px;
	border-radius: 10px 0 0 10px;
}

@media screen and (max-width: 1280px) {
	.slideshowWrapper > section address {
		width: 100%;
	}
}

@media screen and (max-width: 960px) {
	.slideshowWrapper > section address {
		margin-left: auto;
		margin-right: auto;
		max-width: 600px;
		position: relative;
		-webkit-border-radius: 0 0 10px 10px;
		-moz-border-radius: 0 0 10px 10px;
		-ms-border-radius: 0 0 10px 10px;
		border-radius: 0 0 10px 10px;
	}
}

@media screen and (max-width: 640px) {
	.slideshowWrapper > section address .button,
	.slideshowWrapper > section address input[type=button],
	.slideshowWrapper > section address input[type=submit],
	.slideshowWrapper > section address input[type=reset] {
		display: block;
		margin: 15px auto 0;
		text-align: center;
	}
}

.slideshowWrapper > section address h1,
.slideshowWrapper > section address h2,
.slideshowWrapper > section address h3,
.slideshowWrapper > section address h4,
.slideshowWrapper > section address h5,
.slideshowWrapper > section address h6 {
	color: #FFFFFF;
	font-size: 24px;
	font-weight: normal;
	line-height: 27px;
	margin: 0 0 13px;
}

.slideshowWrapper > section address h5 {
	color: #B5D66D;
	font-weight: 700;
}

@media screen and (max-width: 768px) {
	.slideshowWrapper > section address h1,
	.slideshowWrapper > section address h2,
	.slideshowWrapper > section address h3,
	.slideshowWrapper > section address h4,
	.slideshowWrapper > section address h5,
	.slideshowWrapper > section address h6 {
		font-size: 18px;
		line-height: 22px;
		margin: 0 0 13px;
	}
}

.slideshowWrapper > section address h6:after {
	clear: both;
	content: "\0020";
	display: block;
	visibility: hidden;
	zoom: 1;
}

.slideshowWrapper > section address h6 strong {
	color: #FFF;
	display: block;
	font-size: 24px;
	line-height: 21px;
}

@media screen and (max-width: 640px) {
	.slideshowWrapper > section address h6 strong {
		font-size: 16px;
		line-height: 14px;
	}
}

.slideshowWrapper > section address .flexbox {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 640px) {
	.slideshowWrapper > section address .flexbox {
		display: block;
	}
}

.slideshowWrapper > section address hr {
	background: none;
	border-bottom: 2px dotted #293484;
	height: 0;
	margin-bottom: 13px;
}

.slideshowWrapper > section address input,
.slideshowWrapper > section address label,
.slideshowWrapper > section address ol,
.slideshowWrapper > section address p,
.slideshowWrapper > section address .select,
.slideshowWrapper > section address select,
.slideshowWrapper > section address table,
.slideshowWrapper > section address textarea,
.slideshowWrapper > section address ul {
	color: #293484;
	font-family: "Montserrat", sans-serif;
	font-size: 15px;
	font-style: italic;
	line-height: 18px;
}

@media screen and (max-width: 640px) {
	.slideshowWrapper > section address input,
	.slideshowWrapper > section address label,
	.slideshowWrapper > section address ol,
	.slideshowWrapper > section address p,
	.slideshowWrapper > section address .select,
	.slideshowWrapper > section address select,
	.slideshowWrapper > section address table,
	.slideshowWrapper > section address textarea,
	.slideshowWrapper > section address ul {
		font-size: 13px;
		line-height: 16px;
	}
}

.slideshowWrapper > section address table {
	margin-bottom: 0;
	width: 100%;
}

.slideshowWrapper > section address table td,
.slideshowWrapper > section address table th {
	border: 0;
	padding: 0;
}

.slideshowWrapper > section address table button,
.slideshowWrapper > section address table input[type=button],
.slideshowWrapper > section address table input[type=reset],
.slideshowWrapper > section address table input[type=submit],
.slideshowWrapper > section address table .button {
	font-style: normal;
	margin-bottom: 0;
	text-align: center;
}

.slideshowWrapper > figure {
	float: right;
	margin: 0;
	position: relative;
	width: 700px;
	width: 61.188811188811188811188811188811%;
	z-index: 999;
}

@media screen and (max-width: 1280px) {
	.slideshowWrapper > figure {
		width: 50%;
	}
}

@media screen and (max-width: 960px) {
	.slideshowWrapper > figure {
		float: none;
		margin-left: auto;
		margin-right: auto;
		max-width: 700px;
		width: 100%;
		-webkit-box-ordinal-group: 1;
		-webkit-order: 1;
		-moz-box-ordinal-group: 1;
		-ms-flex-order: 1;
		order: 1;
	}
}

.ribbon {
	margin: 0 auto;
	position: relative;
	width: 1168px;
}

@media screen and (max-width: 1280px) {
	.ribbon {
		max-width: 1168px;
		width: auto;
	}
}

.ribbon:before,
.ribbon:after {
	bottom: -12px;
	content: "\0020";
	display: block;
	height: 59px;
	position: absolute;
	width: 55px;
	z-index: -2;
}

.ribbon:before {
	background: url("../media/ribbonEdgeLeft.png") center no-repeat;
	left: -43px;
}

@media screen and (max-width: 1280px) {
	.ribbon:before {
		left: -28px;
	}
}

@media screen and (max-width: 768px) {
	.ribbon:before {
		left: -18px;
	}
}

.ribbon:after {
	background: url("../media/ribbonEdgeRight.png") center no-repeat;
	right: -43px;
}

@media screen and (max-width: 1280px) {
	.ribbon:after {
		right: -28px;
	}
}

@media screen and (max-width: 768px) {
	.ribbon:after {
		right: -18px;
	}
}

.ribbon .widget > h1,
.ribbon .widget > h2,
.ribbon .widget > h3,
.ribbon .widget > h4,
.ribbon .widget > h5,
.ribbon .widget > h6 {
	color: #FFF;
	font-size: 22px;
	line-height: 21px;
	margin: 0 0 16px;
}

.ribbon .widget > h2 {
	flex: 0 1 auto;
	float: left;
	padding: 20px 20px 0;
}

@media screen and (max-width: 640px) {
	.ribbon .widget > h1,
	.ribbon .widget > h2,
	.ribbon .widget > h3,
	.ribbon .widget > h4,
	.ribbon .widget > h5,
	.ribbon .widget > h6 {
		display: none;
	}
}

.ribbon .widget {
	align-items: center;
	background: linear-gradient(#3574A3 0%, #1C3179 100%);
	border-top: 1px solid #758DB3;
	display: flex;
	position: relative;
}

.ribbon .widget:before,
.ribbon .widget:after {
	bottom: -12px;
	content: "\0020";
	display: block;
	height: 0;
	position: absolute;
	width: 0;
	z-index: -1;
}

.ribbon .widget:before {
	border-left: 12px solid transparent;
	border-top: 12px solid #493908;
	left: 0;
}

.ribbon .widget:after {
	border-right: 12px solid transparent;
	border-top: 12px solid #493908;
	right: 0;
}

.ribbon .widget a {
	color: #FFF;
	display: block;
	position: relative;
	width: 100%;
}

@media screen and (max-width: 640px) {
	.ribbon .widget a {
		display: block;
		padding: 0 30px 0 0;
		width: 100%;
	}
}

.ribbon .widget a:before,
.ribbon .widget a:after {
	background: #FFFFFF;
	content: "\0020";
	display: block;
	height: 3px;
	position: absolute;
	right: -8px;
	width: 8px;
}

@media screen and (max-width: 640px) {
	.ribbon .widget a:before,
	.ribbon .widget a:after {
		height: 4px;
		right: 0;
		width: 15px;
	}
}

.ribbon .widget a:before {
	top: 8px;
	/* css3 */
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

@media screen and (max-width: 640px) {
	.ribbon .widget a:before {
		top: 8px;
	}
}

.ribbon .widget a:after {
	top: 12px;
	/* css3 */
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

@media screen and (max-width: 640px) {
	.ribbon .widget a:after {
		top: 16px;
	}
}

.ribbon .widget ol.vcalendar {
	color: #190303;
	display: flex;
	flex: 1 1 auto;
	font-family: "Montserrat", sans-serif;
	font-size: 15px;
	font-weight: bold;
	list-stlye: none;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

.ribbon .widget ol.vcalendar:after {
	clear: both;
	content: "\0020";
	display: block;
	visibility: hidden;
	zoom: 1;
}

.ribbon .widget ol.vcalendar li {
	border-left: 1px solid #FFFFFF;
	border-right: 1px solid #FFFFFF;
	display:  block;
	flex: 1 1 auto;
	float: left;
	padding: 10px 20px 11px;
	width: 25%;
	/* css3 */
	/* -webkit-transition: background 0.25s;
	-moz-transition: background 0.25s;
	-ms-transition: background 0.25s;
	transition: background 0.25s; */
}

@media screen and (max-width: 1280px) {
	.ribbon .widget ol.vcalendar li {
		width: 50%;
	}
}

@media screen and (max-width: 853px) {
	.ribbon .widget ol.vcalendar li {
		border: 0;
		width: 100%;
	}
}

.ribbon .widget ol.vcalendar li:first-child {
	border-left: 0;
}

.ribbon .widget ol.vcalendar li:last-child {
	border-right: 0;
}

@media screen and (max-width: 1280px) {
	.ribbon .widget ol.vcalendar li:nth-child(3) {
		display: none;
	}
}

@media screen and (max-width: 1280px) {
	.ribbon .widget ol.vcalendar li:nth-child(2) {
		border-right: 0;
	}
}

@media screen and (max-width: 853px) {
	.ribbon .widget ol.vcalendar li:nth-child(2) {
		display: none;
	}
}

.ribbon .widget .spacer {
	padding: 20px;
}

.ribbon .widget .spacer p {
	color: #FFF;
	margin: 0;
}

.content {
	margin: 0 auto;
	padding: 0 12px;
}

main {
	background: #F9F8E3;
	margin: 100px auto 0;
	padding: 32px 28px;
	width: 1144px;
}

.short main {
	min-height: 500px;
}

@media screen and (max-width: 1280px) {
	main {
		max-width: 1144px;
		width: auto;
	}

	main aside {
		width: 100%;
	}
}

@media screen and (max-width: 960px) {
	main {
		margin-top: 88px;
	}
}

@media screen and (max-width: 853px) {
	main {
		margin-top: 86px;
	}
}

@media screen and (max-width: 768px) {
	main {
		margin-top: 126px;
	}
}

@media screen and (max-width: 640px) {
	main {
		margin-top: 74px;
		padding: 16px 14px;
	}
}

.homeContent {
	margin: 0 auto;
	padding: 0 12px 35px;
}

.homeContent main {
    border-top: 1px solid #758DB3;
	margin-top: 0;
	padding: 50px 50px 30px;
	display: flex;
}

.content main {
	display: flex;
}

.content main section,
.homeContent main section {
	width: auto;
	flex: 1 1 100%;
}

.content main section > header,
.content main section > figure,
.content main section > article {
	margin-right: 15px;
}

.homeContent main section article {
	margin-right: 15px;
}

.content main aside,
.homeContent main aside {
	width: 400px;
	flex: 1 0 400px;
	max-width: 100%;
}

@media screen and (max-width: 991px) {
	.homeContent main,
	.content main {
		display: block;
	}

	.homeContent main section,
	.content main section {
		width: 100%;
		display: block;
	}

	.content main section > header,
	.content main section > figure,
	.content main section > article {
		margin-right: 0;
	}

	.content main section article,
	.homeContent main section article {
		margin-right: 0;
		width: 100%;
	}

	.content main section article::after,
	.homeContent main section article::after {
		clear: both;
		content: "";
		display: block;
	}

	.homeContent main aside,
	.content main aside {
		width: 100%;
		display: block;
		float: none;
		margin-top: 30px;
	}
}

@media screen and (max-width: 640px) {
	.homeContent main {
		padding: 25px 25px 15px;
	}
}

main:after {
	clear: both;
	content: "\0020";
	display: block;
	visibility: hidden;
	zoom: 1;
}

.homeContent main header h1 {
	color: #293484;
	font-size: 19px;
	line-height: 19px;
	margin-bottom: 0;
}

@media screen and (max-width: 768px) {
	main > section {
		float: none;
		width: auto;
	}
}

main > section:last-child {
	float: none;
	width: 100%;
}

main > section iframe {
	width: 100%;
}

main > section figure {
	margin: 0 0 20px;
}

main > section figure img {
	display: block;
	max-width: 100%;
}

main aside img {
	margin-bottom: 20px;
}

main aside ol.vcalendar {
	list-style: none;
	padding: 0;
}

main aside ol.vcalendar li {
	border-bottom: 1px solid #D1D3D1;
	margin-bottom: 13px;
}

main aside ol.vcalendar li:last-child {
	border-bottom: 0;
	margin-bottom: 0;
}

main aside ol.vcalendar li p {
	margin-bottom: 13px;
}
