.recaptcha_widget {
	background: #EBEDEB;
	border: 1px solid #D1D3D1;
	margin: 0 0 10px;
	max-width: 314px;
	padding: 4px;
	/* css3 */
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	border-radius: 4px;
}

	.recaptcha_widget #recaptcha_image {
		color: #000;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 13px;
		font-weight: normal;
		height: auto !important;
		line-height: normal;
		width: 100% !important;
	}

		.recaptcha_widget #recaptcha_image img {
			border: 3px solid #FFF;
			height: auto;
			margin: 0;
			width: 100%;
			/* css3 */
			-webkit-border-radius: 2px;
			-moz-border-radius: 2px;
			-ms-border-radius: 2px;
			border-radius: 2px;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			-ms-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			-ms-box-shadow: none;
			box-shadow: none;
		}

	.recaptcha_widget .recaptcha_input {
		background: #FFF;
		margin: 4px 0 0;
		padding: 6px;
		/* css3 */
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		-ms-border-radius: 2px;
		border-radius: 2px;
	}

		.recaptcha_widget .recaptcha_input label {
			color: #000;
			display: block;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 13px;
			font-weight: normal;
			height: auto;
			line-height: normal;
			margin: 0 0 4px;
			padding: 0;
			width: 100%;
			/* css3 */
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

		.recaptcha_widget .recaptcha_input input {
			background: #FFF;
			border: 1px solid #E6E6E6;
			box-shadow: none;
			color: #000;
			display: block;
			float: none;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 13px;
			font-weight: normal;
			height: auto;
			line-height: normal;
			margin: 0;
			padding: 4px;
			width: 100%;
			/* css3 */
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			-ms-border-radius: 0;
			border-radius: 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

		.recaptcha_widget .recaptcha_options {
			height: 18px;
			list-style: none;
			margin: 4px 0 0;
			padding: 0;
		}

			.recaptcha_widget .recaptcha_options li {
				background: none;
				float: left;
				list-style: none;
				margin: 0 4px 0 0;
				padding: 0;
			}

			.recaptcha_widget .recaptcha_options li:before {
				display: none;
			}

				.recaptcha_widget .recaptcha_options li a {
					color: #FFF;
					display: block;
					font-size: 16px;
					height: 18px;
					text-decoration: none;
					text-shadow: 0 1px 1px #000;
					width: 20px;
				}

				.recaptcha_widget .recaptcha_options li a:active {
					position: relative;
					text-shadow: none;
					top: 1px;
				}

					.recaptcha_widget .recaptcha_options li a i {
						background-position: center;
						background-repeat: no-repeat;
						background-size: 18px 18px;
						display: block;
						height: 18px;
						width: 18px;
					}

					.recaptcha_widget .recaptcha_options li a i.icon-audio {
						background-image: url("../media/audio.svg");
					}

					.no-svg .recaptcha_widget .recaptcha_options li a i.icon-audio {
						background-image: url("../media/audio.png");
					}

					.recaptcha_widget .recaptcha_options li a i.icon-help {
						background-image: url("../media/help.svg");
					}

					.no-svg .recaptcha_widget .recaptcha_options li a i.icon-help {
						background-image: url("../media/help.png");
					}

					.recaptcha_widget .recaptcha_options li a i.icon-image {
						background-image: url("../media/image.svg");
					}

					.no-svg .recaptcha_widget .recaptcha_options li a i.icon-image {
						background-image: url("../media/image.png");
					}

					.recaptcha_widget .recaptcha_options li a i.icon-refresh {
						background-image: url("../media/refresh.svg");
					}

					.no-svg .recaptcha_widget .recaptcha_options li a i.icon-refresh {
						background-image: url("../media/refresh.png");
					}

	.recaptcha_widget.recaptcha_is_showing_audio #recaptcha_image {
		background: #FFF;
		height: 60px;
		padding: 3px;
		width: 100%;
		/* css3 */
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		-ms-border-radius: 2px;
		border-radius: 2px;
	}

		.recaptcha_widget.recaptcha_is_showing_audio #recapctha_img embed {
			height: 0;
			width: 0;
			overflow: hidden;
		}

		.recaptcha_widget.recaptcha_is_showing_audio #recaptcha_image br {
			display: none;
		}

		.recaptcha_widget.recaptcha_is_showing_audio #recaptcha_image #recaptcha_audio_download {
			color: #000;
			display: block;
		}

/* independent */

.captcha_hide {
	display: none;
}