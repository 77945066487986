@charset "utf-8";
/* CSS Document */

/* Styles written by Jacob Bearce | jacob@weblinxinc.com | jacob@bearce.me */

/* headings */

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #162793;
	font-family: "Montserrat", sans-serif;
	font-weight: bold;
	line-height: normal;
	margin: 0;
	text-transform: uppercase;
}

h1 {
	font-size: 28px;
	margin: 0 0 22px;
}

@media screen and (max-width: 480px) {
	h1 {
		font-size: 20px;
		margin-bottom: 11px;
	}
}

h2 {
	border-top: 1px solid #D1D3D1;
	font-size: 19px;
	padding-top: 20px;
}

h2:first-child,
hr + h2 {
	border-top: 0;
	padding-top: 0;
}

h3 {
	font-size: 19px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 17px;
}

h6 {
	font-size: 15px;
}

/* text */

input,
label,
ol,
p,
.select,
select,
table,
textarea,
ul {
	color: #616161;
	font-family: "Open Sans", sans-serif;
	font-size: 15px;
	font-weight: normal;
	line-height: 20px;
	margin: 0 0 20px;
}

ol {
}

	ol li {
	}

	ol ol, ol ul {
		margin-bottom: 0;
	}

p {
}

table {
}

	table td, table th {
		border: 1px solid #D1D3D1;
		padding: 3px 6px;
		vertical-align: top;
	}

	table thead td, table th {
		background: #EBEDEB;
	}

@media (max-width: 640px) {
	table:not(.no-scroll) {
		border-collapse: collapse !important;
		border-spacing: 0 !important;
		display: block !important;
		overflow-x: auto !important;
		position: relative !important;
		width: 100% !important;
		/* css3 */
		-webkit-overflow-scrolling: touch !important;
		overflow-scrolling: touch !important;
	}

		 table:not(.no-scroll) thead {
			white-space: nowrap !important;
			min-width: 100% !important;
			width: auto !important;
		}

		table:not(.no-scroll) tbody {
			min-width: 100% !important;
			white-space: nowrap !important;
			width: auto !important;
		}
}

ul {
}

	ul li {
	}

	ul ul, ul ol {
		margin-bottom: 0;
	}

/* formatting */

a {
	color: #293484;
	text-decoration: none;
	/* css3 */
	-webkit-transition: color 0.25s;
	-moz-transition: color 0.25s;
	-ms-transition: color 0.25s;
	transition: color 0.25s;
}

a:hover {
	color: #C4CDE9;
}

em,
i {
}

q {
}

strike {
}

b,
strong {
}

u {
}

/* forms */

::-webkit-input-placeholder {
	color: #000;
}

:-moz-placeholder {
	color: #000;
}

::-moz-placeholder {
	color: #000;
}

:-ms-input-placeholder {
	color: #000;
}

.placeholder {
	color: #000;
}

button,
input[type=button],
input[type=reset],
input[type=submit],
.button {
	background: linear-gradient(#AFCD69 0%, #7F8F85 100%);
	border: 0;
	color: #FFF;
	display: inline-block;
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	font-weight: bold;
	line-height: normal;
	margin: 0 0 10px;
	padding: 13px 18px;
	text-transform: uppercase;
	/* css3 */
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	border-radius: 4px;
	-webkit-transition: background 0.25s;
	-moz-transition: background 0.25s;
	-ms-transition: background 0.25s;
	transition: background 0.25s;
}

button:hover,
input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover,
.button:hover {
	background: #AFCD69;
	color: #FFF;
}

input[type=checkbox],
input[type=radio] {
	left: -999em;
	position: absolute;
}

	input[type=checkbox] + label,
	input[type=radio] + label {
		position: relative;
		padding-left: 22px;
	}

	input[type=checkbox] + label:before,
	input[type=radio] + label:before {
		background: #EBEDEB;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 8px;
		border: 1px solid #D1D3D1;
		content: "\0020";
		display: block;
		float: left;
		height: 16px;
		left: 1px;
		position: absolute;
		top: 1px;
		width: 16px;
	}

	input[type=checkbox] + label:hover:before,
	input[type=radio] + label:hover:before {
		cursor: pointer;
	}

	input[type=checkbox]:checked + label:before {
		background-image: url("../media/checkbox.png");
	}

	input[type=radio] + label:before {
		border-radius: 8px;
	}

	input[type=radio]:checked + label:before {
		background-image: url("../media/radio.png");
	}

	input[type=checkbox] + label:after,
	input[type=checkbox] + label:after {
		clear: both;
		content: "\0020";
		display: block;
		visibility: hidden;
		zoom: 1;
	}

input[type=color],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
.select,
textarea {
	background: #EBEDEB;
	border: 1px solid #D1D3D1;
	box-sizing: border-box;
	display: block;
	margin: 0 0 10px;
	outline: none;
	padding: 6px;
	text-overflow: ellipsis;
	width: 100%;
}

label {
	display: block;
	margin: 0 0 10px;
}

label:hover {
	cursor: pointer;
}

.select {
	background-image: url("../media/select.png");
	background-position: right center;
	background-repeat: no-repeat;
	background-size: auto 20px;
	padding: 0;
}

.select:hover {
	cursor: pointer;
}

	.select select {
		background: transparent;
		border: 0;
		box-sizing: content-box;
		display: block;
		outline: none;
		margin: 0;
		padding: 5px 2px;
		text-indent: 0.01px;
    	text-overflow: "";
		width: 100%;
		/* css3 */
		-webkit-appearance: none;
		-mos-appearance: none;
		-ms-appearance: none;
		appearance: none;
	}

	.select select::-ms-expand {
		display: none;
	}

	.select select:hover {
		cursor: pointer;
	}

		.select select option {
			background: #FFF;
			color: #000;
		}

		.select select option:hover {
			backgrond: #DDD;
		}

textarea {
	max-width: 100%;
	min-height: 100px;
	min-width: 100%;
}

/* structural */

blockquote {
	border: 1px solid #D1D3D1;
	margin: 0 0 20px;
	padding: 10px 15px;
}

	blockquote > *:last-child {
		margin-bottom: 0 !important;
	}

hr {
	background: #D1D3D1;
	border: 0;
	clear: both;
	height: 1px;
	margin: 0 0 20px;
	width: 100%;
}

img {
	height: auto !important;
	max-width: 100%;
}

img[align=center],
img.aligncenter {
	display: block;
	margin: 0 auto 10px;
	max-width: 100%;
}

img[align=left],
img.alignleft {
	display: block;
	float: left;
	margin: 0 10px 10px 0;
	max-width: 100%;
}

img[align=right],
img.alignright {
	display: block;
	float: right;
	margin: 0 0 10px 10px;
	max-width: 100%;
}
